import { DromIcon } from "@shared/ui/Icon/ui/Boards/DromIcon";
import { AutoRuIcon } from "@shared/ui/Icon/ui/Boards/AutoRuIcon";
import { AvitoIcon } from "@shared/ui/Icon/ui/Boards/AvitoIcon";
import { AsterKzIcon } from "@shared/ui/Icon/ui/Boards/AsterKzIcon";
import { MyCarIcon } from "@shared/ui/Icon/ui/Boards/MyCarIcon";
import { KolesaKzIcon } from "@shared/ui/Icon/ui/Boards/KolesaKzIcon";
import { MobileDeIcon } from "@shared/ui/Icon/ui/Boards/MobileDeIcon";
import { AvByIcon } from "@shared/ui/Icon/ui/Boards/AvByIcon";
import { PlatformData } from "./types";

export const iconData: PlatformData[] = [
    { Icon: DromIcon, url: "https://drom.ru", label: "Перейти на drom.ru", platformId: 1 },
    { Icon: AutoRuIcon, url: "https://auto.ru", label: "Перейти на auto.ru", platformId: 3 },
    { Icon: AvitoIcon, url: "https://avito.ru", label: "Перейти на avito.ru", platformId: 2 },
    { Icon: AsterKzIcon, url: "https://aster.kz", label: "Перейти на aster.kz", platformId: 6 },
    { Icon: MyCarIcon, url: "https://mycar.kz", label: "Перейти на mycar.kz", platformId: 7 },
    { Icon: KolesaKzIcon, url: "https://kolesa.kz", label: "Перейти на kolesa.kz", platformId: 8 },
    { Icon: MobileDeIcon, url: "https://mobile.de", label: "Перейти на mobile.de", platformId: 9 },
    { Icon: AvByIcon, url: "https://av.by", label: "Перейти на av.by", platformId: 10 }
];