"use client";
import Link from "next/link";
import BlogCard from "../BlogCard/BlogCard";
import Container from "../Container/Container";
import s from "./BlogWidget.module.scss";
import { useGetPostsQuery } from "../../../../app/store/blogApi";
interface BlogWidgetProps {
    showAll?: boolean;
    title: string;
    id?: number;
}

const BlogWidget: React.FC<BlogWidgetProps> = ({ title, showAll, id }) => {
    const { data, isFetching } = useGetPostsQuery({
        count: 3,
        exclude: id ?? 0
    });

    if (data) {
        return (
            <div className={s.BlogWidget}>
                <Container>
                    <div className={s.Header}>
                        <h2>{title}</h2>
                        {showAll && (
                            <Link href="/blog" className={s.Link}>
                                Смотреть все
                            </Link>
                        )}
                    </div>
                    <div className={s.Cards}>
                        {data.data.map((item: any) => {
                            //Only published posts
                            if (item.status === "publish" && item.slug) {
                                return (
                                    <BlogCard
                                        slug={item.slug}
                                        title={item.title?.rendered ?? ""}
                                        date={item.date}
                                        image={
                                            item.x_featured_media_large ??
                                            "data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAABCAYAAAAfFcSJAAAADUlEQVR42mP8/up7PQAJEQNZlBQt7QAAAABJRU5ErkJggg=="
                                        }
                                        views={item.views ?? null}
                                        key={item.id}
                                    />
                                );
                            }
                        })}
                    </div>
                </Container>
            </div>
        );
    } else {
        return null;
    }
};

export default BlogWidget;