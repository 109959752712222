export const PopularBrands = [
    { name: "LADA (ВАЗ)", alias: "lada-vaz" },
    { name: "Toyota", alias: "toyota" },
    { name: "Kia", alias: "kia" },
    { name: "Hyundai", alias: "hyundai" },
    { name: "Nissan", alias: "nissan" },
    { name: "Volkswagen", alias: "volkswagen" },
    { name: "BMW", alias: "bmw" },
    { name: "Mercedes-Benz", alias: "mercedesbenz" },
    { name: "Honda", alias: "honda" },
    { name: "Ford", alias: "ford" },
    { name: "Mitsubishi", alias: "mitsubishi" },
    { name: "Chevrolet", alias: "chevrolet" },
    { name: "Renault", alias: "renault" },
    { name: "Mazda", alias: "mazda" },
    { name: "Audi", alias: "audi" },
    { name: "Skoda", alias: "skoda" },
    { name: "Opel", alias: "opel" },
    { name: "Chery", alias: "chery" },
    { name: "ГАЗ", alias: "gaz" },
    { name: "Lexus", alias: "lexus" },
    { name: "Haval", alias: "haval" },
    { name: "УАЗ", alias: "uaz" },
    { name: "Subaru", alias: "subaru" },
    { name: "Daewoo", alias: "daewoo" },
    { name: "Peugeot", alias: "peugeot" },
    { name: "Suzuki", alias: "suzuki" },
    { name: "Geely", alias: "geely" },
    { name: "Exeed", alias: "exeed" },
    { name: "Land Rover", alias: "land-rover" },
    { name: "Volvo", alias: "volvo" },
    // Новые алиасы
    { name: "Citroen", alias: "citroen" },
    { name: "Infiniti", alias: "infiniti" },
    { name: "Porsche", alias: "porsche" },
    { name: "Changan", alias: "changan" },
    { name: "SsangYong", alias: "ssangyong" },
    { name: "OMODA", alias: "omoda" },
    { name: "Jeep", alias: "jeep" },
    { name: "Daihatsu", alias: "daihatsu" },
    { name: "ЗАЗ", alias: "zaz" },
    { name: "Fiat", alias: "fiat" },
    { name: "Genesis", alias: "genesis" },
    { name: "Datsun", alias: "datsun" },
    { name: "Great Wall", alias: "great-wall" },
    { name: "Москвич", alias: "moskvich" },
    { name: "Dodge", alias: "dodge" },
    { name: "Mini", alias: "mini" },
    { name: "Jaguar", alias: "jaguar" },
    { name: "Lifan", alias: "lifan" }
];