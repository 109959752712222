import type { NextPage } from "next";
import Head from "next/head";
import Template from "@app/Template/Template";
import MainScreen from "../components/Screens/MainScreen/MainScreen";
import BlogWidget from "../components/Features/Blog/BlogWidget/BlogWidget";

const Home: NextPage = () => {
    return (
        <Template>
            <Head>
                <title>LIKVI.COM - Поиск автомобилей</title>
                <meta
                    name="description"
                    content="Likvi.com - агрегатор автомобилей, продажа авто"
                />
            </Head>
            <MainScreen />
            <BlogWidget title={"Блог"} showAll />
        </Template>
    );
};

export const getServerSideProps = async () => {
    return {
        props: {}
    };
};

export default Home;